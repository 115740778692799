export const techStack = [
  {
    title: 'Back-end',
    link: '/find-developers/back-end',
  },
  {
    title: 'Front-end',
    link: '/find-developers/front-end',
  },
  {
    title: 'Android',
    link: '/find-developers/android',
  },
  {
    title: 'iOS',
    link: '/find-developers/ios',
  },
  {
    title: 'Java',
    link: '/find-developers/java',
  },
  {
    title: 'PHP',
    link: '/find-developers/php',
  },
  {
    title: 'React',
    link: '/find-developers/react-js',
  },
  {
    title: 'Python',
    link: '/find-developers/python',
  },
];

export const techStackAe = [
  {
    title: 'Back-end',
    link: 'https://andersenlab.com/find-developers/back-end',
  },
  {
    title: 'Front-end',
    link: 'https://andersenlab.com/find-developers/front-end',
  },
  {
    title: 'Android',
    link: 'https://andersenlab.com/find-developers/android',
  },
  {
    title: 'iOS',
    link: 'https://andersenlab.com/find-developers/ios',
  },
  {
    title: 'Java',
    link: 'https://andersenlab.com/find-developers/java',
  },
  {
    title: 'PHP',
    link: 'https://andersenlab.com/find-developers/php',
  },
  {
    title: 'React',
    link: 'https://andersenlab.com/find-developers/react-js',
  },
  {
    title: 'Python',
    link: 'https://andersenlab.com/find-developers/python',
  },
];

export const industries = [
  {
    title: 'Financial Services',
    link: '/industries/financial-services',
  },
  {
    title: 'Healthcare',
    link: '/industries/healthcare',
  },
  {
    title: 'Logistics',
    link: '/industries/logistics',
  },
  {
    title: 'Travel & Hospitality',
    link: '/industries/travel-hospitality',
  },
  {
    title: 'Automotive',
    link: '/industries/automotive',
  },
  {
    title: 'Media & Entertainment',
    link: '/industries/media-and-entertainment',
  },
  {
    title: 'Telecom',
    link: '/industries/telecom',
  },
  {
    title: 'Manufacturing',
    link: '/industries/manufacturing',
  },
  {
    title: 'Real Estate',
    link: '/industries/real-estate',
  },
  {
    title: 'iGaming',
    link: '/industries/gambling',
  },
  {
    title: 'Retail',
    link: '/industries/retail',
  },
  {
    title: 'Nonprofit',
    link: '/industries/nonprofit-software-development',
  },
];

export const industriesDe = [
  {
    title: 'Finanzdienstleistungen',
    link: '/industries/financial-services',
  },
  {
    title: 'Gesundheitswesen',
    link: '/industries/healthcare',
  },
  {
    title: 'Logistik',
    link: '/industries/logistics',
  },
  {
    title: 'Reise & Unterkunft',
    link: '/industries/travel-hospitality',
  },
  {
    title: 'Automobilindustrie',
    link: '/industries/automotive',
  },
  {
    title: 'Media & Unterhaltung',
    link: '/industries/media-and-entertainment',
  },
  {
    title: 'Telekommunikation',
    link: '/industries/telecom',
  },
  {
    title: 'Manufacturing',
    link: '/industries/manufacturing',
  },
  {
    title: 'Immobilien',
    link: '/industries/real-estate',
  },
  {
    title: 'iGaming',
    link: '/industries/gambling',
  },
  {
    title: 'Einzelhandel',
    link: '/industries/retail',
  },
];

export const industriesAe = [
  {
    title: 'خدمات مالية',
    link: 'https://andersenlab.com/industries/financial-services',
  },
  {
    title: 'الصحة والرعاية الصحية',
    link: 'https://andersenlab.com/industries/healthcare',
  },
  {
    title: 'خدمات لوجستية',
    link: 'https://andersenlab.com/industries/logistics',
  },
  {
    title: 'السفر وكرم الضيافة',
    link: 'https://andersenlab.com/industries/travel-hospitality',
  },
  {
    title: 'السيارات',
    link: 'https://andersenlab.com/industries/automotive',
  },
  {
    title: 'وسائل الإعلام والترفيه',
    link: 'https://andersenlab.com/industries/media-and-entertainment',
  },
  {
    title: 'الإتصالات',
    link: 'https://andersenlab.com/industries/telecom',
  },
  {
    title: 'التصنيع',
    link: 'https://andersenlab.com/industries/manufacturing',
  },
  {
    title: 'العقارات',
    link: 'https://andersenlab.com/industries/real-estate',
  },
  {
    title: 'ألعاب الانترنيت',
    link: 'https://andersenlab.com/industries/gambling',
  },
  {
    title: 'تجارة التجزئة ',
    link: 'https://andersenlab.com/industries/retail',
  },
];

export const industriesAz = [
  {
    title: 'Maliyyə Xidmətləri',
    link: 'https://andersenlab.com/industries/financial-services',
  },
  {
    title: 'Səhiyyə',
    link: 'https://andersenlab.com/industries/healthcare',
  },
  {
    title: 'Logistika',
    link: 'https://andersenlab.com/industries/logistics',
  },
  {
    title: 'Səyahət və Qonaqpərvərlik',
    link: 'https://andersenlab.com/industries/travel-hospitality',
  },
  {
    title: 'Avtomobil',
    link: 'https://andersenlab.com/industries/automotive',
  },
  {
    title: 'Mediya və Əyləncə',
    link: 'https://andersenlab.com/industries/media-and-entertainment',
  },
  {
    title: 'Telekommunikasiya',
    link: 'https://andersenlab.com/industries/telecom',
  },
  {
    title: 'İstehsalat',
    link: 'https://andersenlab.com/industries/manufacturing',
  },
  {
    title: 'Daşınmaz Əmlak',
    link: 'https://andersenlab.com/industries/real-estate',
  },
  {
    title: 'iOyunlar',
    link: 'https://andersenlab.com/industries/gambling',
  },
  {
    title: 'Pərakəndə satış',
    link: 'https://andersenlab.com/industries/retail',
  },
  {
    title: 'Qeyri-kommersiya',
    link: 'https://andersenlab.com/industries/nonprofit-software-development',
  },
  {
    title: 'Tikinti',
    link: 'https://andersenlab.com/industries/construction-software-development',
  },
  {
    title: 'Kommunal və Enerji',
    link: 'https://andersenlab.com/industries/custom-energy-management-software',
  },
];

export const services = [
  {
    title: 'Mobile Development',
    link: '/services/mobile-development',
  },
  {
    title: 'Web Development',
    link: '/services/web-development',
  },
  {
    title: 'Quality Assurance',
    link: '/services/quality-assurance-services',
  },
  {
    title: 'Staff Augmentation',
    link: '/services/software-team-staff-augmentation',
  },
  {
    title: 'Product Development Services',
    link: '/services/product-development-services',
  },
  {
    title: 'Dedicated Team',
    link: '/services/dedicated-team',
  },
  {
    title: 'UI/UX Design',
    link: '/services/design-studio',
  },
  {
    title: 'DevOps Services',
    link: '/services/devops',
  },
  {
    title: 'Business Analysis',
    link: '/services/business-analysts',
  },
  {
    title: 'Solution Architecture',
    link: '/services/architecture',
  },
  {
    title: 'IoT Development Services',
    link: '/services/iot-development-services',
  },
  {
    title: 'Startups & MVP Services',
    link: '/services/mvp-development-services-andersen',
  },
  {
    title: 'SaaS',
    link: '/services/andersen-saas-development-services',
  },
  {
    title: 'Database',
    link: '/services/data-development-services-with-andersen',
  },
];

export const servicesDe = [
  {
    title: 'Mobile-Entwicklung',
    link: '/services/mobile-development',
  },
  {
    title: 'Webentwicklung',
    link: '/services/web-development',
  },
  {
    title: 'Qualitätssicherung',
    link: '/services/quality-assurance-services',
  },
  {
    title: 'Dienstleistungen zur Produktentwicklung',
    link: '/services/product-development-services',
  },
  {
    title: 'UI/UX Design',
    link: '/services/design-studio',
  },
  {
    title: 'DevOps',
    link: '/services/devops',
  },
  {
    title: 'Business Analyse',
    link: '/services/business-analysts',
  },
  {
    title: 'Lösungsarchitektur',
    link: '/services/architecture',
  },
  {
    title: 'Teamerweiterung',
    link: '/services/software-team-staff-augmentation',
  },
  {
    title: 'Database',
    link: '/services/data-development-services-with-andersen',
  },
  {
    title: 'Dediziertes Team',
    link: '/services/dedicated-team',
  },
];

export const servicesAe = [
  {
    title: 'تطوير المحمول',
    link: 'https://andersenlab.com/services/mobile-development',
  },
  {
    title: 'تطوير الشبكة',
    link: 'https://andersenlab.com/services/web-development',
  },
  {
    title: 'تاكيد الجودة',
    link: 'https://andersenlab.com/services/quality-assurance-services',
  },
  {
    title: 'زيادة عدد الموظفين',
    link: 'https://andersenlab.com/services/software-team-staff-augmentation',
  },
  {
    title: 'خدمات تطوير المنتجات',
    link: 'https://andersenlab.com/services/product-development-services',
  },
  {
    title: 'فريق مخلص',
    link: 'https://andersenlab.com/services/dedicated-team',
  },
  {
    title: 'تصميم UI / UX',
    link: '/services/design-studio',
  },
  {
    title: 'خدمات DevOps',
    link: 'https://andersenlab.com/services/devops',
  },
  {
    title: 'تحليل الأعمال',
    link: 'https://andersenlab.com/services/business-analysts',
  },
  {
    title: 'هندسة الحلول',
    link: 'https://andersenlab.com/services/architecture',
  },
  {
    title: 'خدمات تطوير إنترنت الأشياء',
    link: 'https://andersenlab.com/services/iot-development-services',
  },
  {
    title: 'خدمات للشركات الناشئة والحد الأدنى من المنتجات القابلة للاستمرار (MVP)',
    link: 'https://andersenlab.com/services/mvp-development-services-andersen',
  },
  {
    title: 'برمجيات مثل خدمات (SaaS)',
    link: 'https://andersenlab.com/services/andersen-saas-development-services',
  },
];

export const servicesAz = [
  {
    title: 'Mobil İnkişaf',
    link: 'https://andersenlab.com/services/mobile-development',
  },
  {
    title: 'Veb İnkişafı',
    link: 'https://andersenlab.com/services/web-development',
  },
  {
    title: 'Keyfiyyətə Zəmanət',
    link: 'https://andersenlab.com/services/quality-assurance-services',
  },
  {
    title: 'Ştat Artımı',
    link: 'https://andersenlab.com/services/software-team-staff-augmentation',
  },
  {
    title: 'Məhsul İnkişafı Xidmətləri',
    link: 'https://andersenlab.com/services/product-development-services',
  },
  {
    title: 'Fədakar Komanda',
    link: 'https://andersenlab.com/services/dedicated-team',
  },
  {
    title: 'UI/UX Dizayn',
    link: 'https://andersenlab.com/services/design-studio',
  },
  {
    title: 'DevOps Xidmətləri',
    link: 'https://andersenlab.com/services/devops',
  },
  {
    title: 'Biznes Analiz',
    link: 'https://andersenlab.com/services/business-analysts',
  },
  {
    title: 'Həllərin Arxitekturası',
    link: 'https://andersenlab.com/services/architecture',
  },
  {
    title: 'IoT İnkişaf Xidmətləri',
    link: 'https://andersenlab.com/services/iot-development-services',
  },
  {
    title: 'Startups və MVP Xidmətləri',
    link: 'https://andersenlab.com/services/mvp-development-services-andersen',
  },
  {
    title: 'Xidmət məqsədli Proqram Təminatı',
    link: 'https://andersenlab.com/services/andersen-saas-development-services',
  },
  {
    title: 'Məlumat Bazası',
    link: 'https://andersenlab.com/services/data-development-services-with-andersen',
  },
];

export const projects = [
  {
    title: 'Verivox',
    link: '/project-cases/price-comparison-portal',
  },
  {
    title: 'Management Events',
    link: '/project-cases/event-management-system',
  },
  {
    title: 'G Bank',
    link: '/project-cases/mobile-banking-app-for-small-businesses',
  },
  {
    title: 'TUI',
    link: '/project-cases/solutions-for-internal-use-and-workflows',
  },
  {
    title: 'Exigo',
    link: '/project-cases/end-to-end-direct-sales-ecosystem',
  },
  {
    title: 'FTI',
    link: '/project-cases/internal-management-system-for-tourism',
  },
  {
    title: 'Universkin',
    link: '/project-cases/healthcare-tool-for-skin-conditions',
  },
];

export const projectsDe = [
  {
    title: 'Verivox',
    link: '/project-cases/price-comparison-portal',
  },
  {
    title: 'TUI',
    link: '/project-cases/solutions-for-internal-use-and-workflows',
  },
  {
    title: 'Pre-A',
    link: '/project-cases/remote-e-learning-solution',
  },
  {
    title: 'Elanders',
    link: '/project-cases/andersen-custom-software-platform-to-print-handle-client-docs ',
  },
  {
    title: 'FTI',
    link: '/project-cases/internal-management-system-for-tourism',
  },
  {
    title: 'Mercedes',
    link: '/project-cases/entertainment-e-space-for-luxury-car-brand',
  },
  {
    title: 'Samsung',
    link: '/project-cases/app-to-watch-video-content-smart-tvs',
  },
  {
    title: 'Management Events',
    link: '/project-cases/event-management-system',
  },
];

export const projectsAe = [
  {
    title: 'Verivox',
    link: 'https://andersenlab.com/project-cases/price-comparison-portal',
  },
  {
    title: 'Management Events',
    link: 'https://andersenlab.com/project-cases/event-management-system',
  },
  {
    title: 'G Bank',
    link: 'https://andersenlab.com/project-cases/mobile-banking-app-for-small-businesses',
  },
  {
    title: 'TUI',
    link: 'https://andersenlab.com/project-cases/solutions-for-internal-use-and-workflows',
  },
  {
    title: 'Exigo',
    link: 'https://andersenlab.com/project-cases/end-to-end-direct-sales-ecosystem',
  },
  {
    title: 'FTI',
    link: 'https://andersenlab.com/project-cases/internal-management-system-for-tourism',
  },
  {
    title: 'Universkin',
    link: 'https://andersenlab.com/project-cases/healthcare-tool-for-skin-conditions',
  },
];

export const company = [
  {
    title: 'About us',
    link: '/about-us',
  },
  {
    title: 'Corporate life',
    link: 'https://people.andersenlab.com/corporate-life',
    region: 'cis',
  },
  {
    title: 'Careers',
    link: 'https://people.andersenlab.com',
    region: 'cis',
  },
  {
    title: 'Insights',
    link: '/company/articles',
  },
  {
    title: 'Testimonials',
    link: '/company/testimonials',
  },
  {
    title: 'GTC',
    link: '/gtc',
    target: '_blank',
  },
  {
    title: 'GTC for Consultancy services',
    link: '/gtc-for-consultancy-services',
    target: '_blank',
  },
  {
    title: 'GTC for Consultancy services of UAB «Andersen Soft»',
    link: '/consultancy-services-andersen-agreements',
    target: '_blank',
  },
  {
    title: 'GTC for Consultancy services of Andersen Germany GmbH',
    link: '/gtc-for-consultancy-services-germany-gmbh',
    target: '_blank',
  },
];

export const companyDe = [
  {
    title: 'Über uns',
    link: '/about-us',
  },
  {
    title: 'Firmenleben',
    link: 'https://people.andersenlab.com/corporate-life',
    region: 'cis',
  },
  {
    title: 'Karriere',
    link: 'https://people.andersenlab.com',
    region: 'cis',
  },
  {
    title: 'Insights',
    link: '/company/articles',
  },
  {
    title: 'Referenzen',
    link: '/company/testimonials',
  },
  {
    title: 'AGB',
    link: '/agb',
    target: '_blank',
  },
  {
    title: 'GTC for Consultancy services',
    link: 'https://andersenlab.com/gtc-for-consultancy-services',
    target: '_blank',
  },
  {
    title: 'GTC for Consultancy services of UAB «Andersen Soft»',
    link: 'https://andersenlab.com/consultancy-services-andersen-agreements',
    target: '_blank',
  },
  {
    title: 'GTC for Consultancy services of Andersen Germany GmbH',
    link: 'https://andersenlab.com/gtc-for-consultancy-services-germany-gmbh',
    target: '_blank',
  },
];

export const companyAe = [
  {
    title: 'معلومات عنا',
    link: 'https://andersenlab.com/about-us',
  },
  {
    title: 'حياة الشركة',
    link: 'https://people.andersenlab.com/corporate-life',
    region: 'cis',
  },
  {
    title: 'وظائف',
    link: 'https://people.andersenlab.com',
    region: 'cis',
  },
  {
    title: 'مقالات',
    link: 'https://andersenlab.com/company/articles',
  },
  {
    title: 'الشهادات - التوصيات',
    link: 'https://andersenlab.com/company/testimonials',
  },
  {
    title: 'GTC for Consultancy services',
    link: 'https://andersenlab.com/gtc-for-consultancy-services',
    target: '_blank',
  },
  {
    title: 'GTC for Consultancy services of UAB Andersen Soft',
    link: 'https://andersenlab.com/consultancy-services-andersen-agreements',
    target: '_blank',
  },
  {
    title: 'GTC for Consultancy services of Andersen Germany GmbH',
    link: 'https://andersenlab.com/gtc-for-consultancy-services-germany-gmbh',
    target: '_blank',
  },
];

export const companyAz = [
  {
    title: 'Haqqımızda',
    link: 'https://andersenlab.com/about-us',
  },
  {
    title: 'Corporate life',
    link: 'https://people.andersenlab.com/corporate-life',
    region: 'cis',
  },
  {
    title: 'Karyera',
    link: 'https://people.andersenlab.com',
    region: 'cis',
  },
  {
    title: 'İdeyalar',
    link: 'https://andersenlab.com/company/articles',
  },
  {
    title: 'Rekomendasiyalar',
    link: 'https://andersenlab.com/company/testimonials',
  },
  {
    title: 'GTC',
    link: 'https://andersenlab.com/gtc',
    target: '_blank',
  },
  {
    title: 'GTC for Consultancy services',
    link: 'https://andersenlab.com/gtc-for-consultancy-services',
    target: '_blank',
  },
  {
    title: 'GTC for Consultancy services of UAB «Andersen Soft»',
    link: 'https://andersenlab.com/consultancy-services-andersen-agreements',
    target: '_blank',
  },
  {
    title: 'GTC for Consultancy services of Andersen Germany GmbH',
    link: 'https://andersenlab.com/gtc-for-consultancy-services-germany-gmbh',
    target: '_blank',
  },
];

export const offices = [35, 8, 19, 21];

export const officesUS = [33, 8, 21, 35];

export const officesMena = [34, 35, 19, 21];

export const officesAz = [29, 35, 8, 21];

export const officesAe = [34, 35, 19, 21];

export const officesKz = [37, 35, 19, 21];
